<template>
  <div>
    <el-table :data="tableData" border>
      <el-table-column align="center" prop="edit_time" label="修改时间" width="150"> </el-table-column>
      <el-table-column align="center" prop="code" label="编号" width="150"> </el-table-column>
      <el-table-column align="center" prop="date" label="日期" width="100"> </el-table-column>
      <el-table-column align="center" prop="cangku_name" label="出入库房" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiao_name" label="材料名称" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige_name" label="材料规格" width="100"> </el-table-column>
      <el-table-column align="center" label="数量">
        <el-table-column align="center" prop="in_amount" label="合同内" width="70"> </el-table-column>
        <el-table-column align="center" prop="add_amount" label="增量" width="50"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="租金">
        <el-table-column align="center" prop="zujin_danjia" label="单价" width="50"> </el-table-column>
        <el-table-column align="center" prop="zujin_hj" label="租金合计" width="70"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="押金">
        <el-table-column align="center" prop="yajin_danjia" label="每片押金" width="70"> </el-table-column>
        <el-table-column align="center" prop="yajin_hj" label="合计" width="50"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="吊装费">
        <el-table-column align="center" prop="diaozhuangfei_dunshu" label="吨数" width="70"> </el-table-column>
        <el-table-column align="center" prop="diaozhuangfei_hj" label="合计" width="70"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" label="运费">
        <el-table-column align="center" prop="yunfei_danjia" label="单价" width="50"> </el-table-column>
        <el-table-column align="center" prop="yunfei_hj" label="合计" width="50"> </el-table-column>
      </el-table-column>
      <el-table-column align="center" prop="chuban_hj" label="出板合计金额" width="70"> </el-table-column>
      <el-table-column align="center" prop="jinpai_kehu" label="金牌客户" width="70">
        <template slot-scope="scope">
          <span>{{ scope.row.jinpai_kehu ? '是' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="lengzhuang_lianmeng" label="冷弯联盟" width="70">
        <template slot-scope="scope">
          <span>{{ scope.row.lengzhuang_lianmeng ? '是' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="shiji_huiru" label="实际汇入" width="70"> </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="200"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    tableData: Array
  }
}
</script>
